import React, { useRef, useState } from 'react';
import axios from 'axios';
import { t } from '../../../js/common/translations';
import { ThemeProvider } from '../../contexts/theme';
import ReCAPTCHA from 'react-google-recaptcha';
import inputValidator from '../../utils/inputValidator';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  PhoneInput,
  Select,
  SvgIcon,
  Typography,
  Link,
  FormControlLabel
} from '../elements';

import { ConfirmationWrapper, InputWrapper, LandlordTextField, SubmitWrapper } from './LandlordContactForm.styles';

const INITIAL_FORM_VALUES = {
  fullName: '',
  phoneNumber: '',
  email: '',
  cityName: 'other',
  neighborhoodName: {
    'sao-paulo': [
      { neighborhoodSlug: 'itaim-bibi', neighborhoodName: 'Itaim Bibí' },
      { neighborhoodSlug: 'jardim-paulista', neighborhoodName: 'Jardim Paulista' },
      { neighborhoodSlug: 'pinheiros', neighborhoodName: 'Pinheiros' },
      { neighborhoodSlug: 'vila-olimpia', neighborhoodName: 'Vila Olímpia' },
      { neighborhoodSlug: 'vila-nova-conceicao', neighborhoodName: 'Vila Conceição' },
      { neighborhoodSlug: 'moema', neighborhoodName: 'Moema' },
      { neighborhoodSlug: 'vila-uberabinha', neighborhoodName: 'Vila Uberabinha' },
      { neighborhoodSlug: 'brooklin', neighborhoodName: 'Brooklin' },
      { neighborhoodSlug: 'other', neighborhoodName: t('landlords_page.hero.contact_form.fields.other') }
    ],
    'rio-de-janeiro': [
      { neighborhoodSlug: 'ipanema', neighborhoodName: 'Ipanema' },
      { neighborhoodSlug: 'leblon', neighborhoodName: 'Leblon' },
      { neighborhoodSlug: 'other', neighborhoodName: t('landlords_page.hero.contact_form.fields.other') }
    ],
    brasilia: [
      { neighborhoodSlug: 'asa-norte', neighborhoodName: 'Asa Norte' },
      { neighborhoodSlug: 'asa-sul', neighborhoodName: 'Asa Sul' },
      { neighborhoodSlug: 'other', neighborhoodName: t('landlords_page.hero.contact_form.fields.other') }
    ],
    other: [
      { neighborhoodSlug: 'other', neighborhoodName: t('landlords_page.hero.contact_form.fields.other') }
    ]
  },
  neighborhoodValue: '',
  cityNameValue: '',
  addressName: '',
  zipcode: '',
  sqmValue: '',
  agreement: false
};

const LandlordContactForm = ({
  recaptchaSiteKey,
  onClose,
  url,
  clickSource = '',
  currentMember
}) => {
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);
  const [formErrors, setFormErrors] = useState([]);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const formRef = useRef(null);

  const formValidations = [
    ['fullName', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['phoneNumber', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['email', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['email', { validation: 'email', errorMessage: t('form_errors.email_invalid') }],
    ['addressName', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['addressName', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['cityNameValue', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['neighborhoodValue', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['sqmValue', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['phoneNumber', { validation: () => /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(formValues.phoneNumber), errorMessage: t('form_errors.phone_invalid') }],
    ['agreement', { validation: 'required', errorMessage: t('form_errors.agreement_checkbox_not_checked') }]
  ];

  const handleChange = (inputName) => ({ target: { type, value, checked } }) => {
    const inputValue = type === 'checkbox' ? checked : value;
    const validate = {
      [inputName]: inputValue
    };

    if (inputName === 'cityNameValue') {
      setFormValues(previousState => ({
        ...previousState,
        cityName: value
      }));
    }

    const errors = inputValidator(validate, formValidations.filter(el => el[0] === inputName));
    setFormErrors(errors);

    setFormValues(previousState => ({
      ...previousState,
      [inputName]: inputValue
    }));
  };

  const getErrorMessage = (inputName) => {
    return formErrors.filter(el => el.field === inputName)[0]?.error;
  };

  const submitForm = async formData => {
    setLoadingStatus(true);

    await axios.post(url, formData)
      .then(() => {
        setSubmissionSuccess(true);
        setFormValues(INITIAL_FORM_VALUES);
      })
      .catch(_err => {
        setFormErrors([{
          field: 'failedSubmission',
          error: t('landlords_page.hero.contact_form.failure_message')
        }]);
      });

    setLoadingStatus(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(formRef.current);

    const errors = inputValidator(formValues, formValidations);

    if (errors.length) setFormErrors(errors);
    else submitForm(formData);
  };

  return (
    <ThemeProvider>
      {!submissionSuccess ? (
        <>
          <Box
            className={`owners-${clickSource}modal`}
            mx={[1, 1, 0]}
            my={1}
            component="form"
            ref={formRef}
          >
            <Divider sx={{ mt: -1, px: 2, display: ['block', 'none'] }} />
            <InputWrapper>
              <LandlordTextField
                className={`owners-${clickSource}modal-full-name`}
                placeholder={t('landlords_page.hero.contact_form.fields.full_name')}
                label={t('landlords_page.hero.contact_form.fields.full_name')}
                sx={{ mb: 0.5, mt: 1, mr: [0, 0, 0.25] }}
                helperText={getErrorMessage('fullName')}
                error={!!getErrorMessage('fullName')}
                name="landlord_contact[full_name]"
                onChange={handleChange('fullName')}
                autoComplete={'full-name'}
                id="full-name"
                required
              />
              <PhoneInput
                className={`owners-${clickSource}modal-phone`}
                label={t('landlords_page.hero.contact_form.fields.phone')}
                sx={{ mb: 0.5, mt: [0.5, 0.5, 1], ml: [0, 0, 0.25] }}
                helperText={getErrorMessage('phoneNumber')}
                error={!!getErrorMessage('phoneNumber')}
                name="landlord_contact[phone]"
                onChange={(value) => handleChange('phoneNumber')({ target: { value } })}
                autoFormat={false}
                id="phone-input"
                required
                fullWidth
              />
            </InputWrapper>
            <InputWrapper>
              <LandlordTextField
                className={`owners-${clickSource}modal-email`}
                placeholder={t('landlords_page.hero.contact_form.fields.email')}
                label={t('landlords_page.hero.contact_form.fields.email')}
                sx={{ my: 0.5, minWidth: 325, mr: 0.25 }}
                helperText={getErrorMessage('email')}
                error={!!getErrorMessage('email')}
                onChange={handleChange('email')}
                name="landlord_contact[email]"
                autoComplete={'email'}
                id="email"
                required
              />
              <FormControl
                className={`owners-${clickSource}modal-city`}
                formHelperText={getErrorMessage('cityNameValue')}
                sx={{ mb: 0.5, width: 1, my: 0.5, ml: [0, 0, 0.25] }}
                error={!!getErrorMessage('cityNameValue')}
                id="landlord_contact_city"
                required
                formHelperTextProps={
                  { sx: { mx: 0 } }
                }
              >
                <InputLabel id="city-label">{t('landlords_page.hero.contact_form.fields.city')}</InputLabel>
                <Select
                  label={t('landlords_page.hero.contact_form.fields.city')}
                  onChange={handleChange('cityNameValue')}
                  value={formValues.cityNameValue}
                  name="landlord_contact[city]"
                  labelId="city-label"
                >
                  <MenuItem value={'sao-paulo'}>São Paulo</MenuItem>
                  <MenuItem value={'rio-de-janeiro'}>Rio de Janeiro</MenuItem>
                  <MenuItem value={'brasilia'}>Brasília</MenuItem>
                  <MenuItem value={'other'}>{t('landlords_page.hero.contact_form.fields.other_city')}</MenuItem>
                </Select>
              </FormControl>
            </InputWrapper>
            <InputWrapper>
              <FormControl
                className={`owners-${clickSource}modal-area`}
                formHelperText={getErrorMessage('neighborhoodValue')}
                error={!!getErrorMessage('neighborhoodValue')}
                sx={{ my: 0.5, minWidth: 225, mr: [0, 0, 0.25] }}
                id="landlord_contact_neighborhood"
                required
                formHelperTextProps={
                  { sx: { mx: 0 } }
                }
              >
                <InputLabel id="neighborhood-label">{t('landlords_page.hero.contact_form.fields.neighborhood')}</InputLabel>
                <Select
                  label={t('landlords_page.hero.contact_form.fields.neighborhood')}
                  onChange={handleChange('neighborhoodValue')}
                  name="landlord_contact[neighborhood]"
                  value={formValues.neighborhoodValue}
                  labelId="neighborhood-label"
                >
                  {formValues.neighborhoodName[formValues.cityName].map(({ neighborhoodSlug, neighborhoodName }, index) => (
                    <MenuItem
                      value={neighborhoodSlug}
                      key={index}
                    >
                      {neighborhoodName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LandlordTextField
                className={`owners-${clickSource}modal-address`}
                placeholder={t('landlords_page.hero.contact_form.fields.address')}
                label={t('landlords_page.hero.contact_form.fields.address')}
                helperText={getErrorMessage('addressName')}
                error={!!getErrorMessage('addressName')}
                onChange={handleChange('addressName')}
                name="landlord_contact[address]"
                autoComplete={'address'}
                id="address"
                required
                sx={{ mb: 0.5, width: 1, my: 0.5, ml: [0, 0, 0.25] }}
              />
            </InputWrapper>
            <InputWrapper>
              <LandlordTextField
                className={`owners-${clickSource}modal-zip-code`}
                placeholder={t('landlords_page.hero.contact_form.fields.zip_code')}
                label={t('landlords_page.hero.contact_form.fields.zip_code')}
                helperText={getErrorMessage('zipcode')}
                onChange={handleChange('zipcode')}
                error={!!getErrorMessage('zipcode')}
                name="landlord_contact[zip_code]"
                autoComplete={'zip_code'}
                id="zip-code"
                sx={{ my: 0.5, width: 1, mr: 0.25 }}
              />
              <LandlordTextField
                className={`owners-${clickSource}modal-total-sqm`}
                placeholder={t('landlords_page.hero.contact_form.fields.total_sqm')}
                label={t('landlords_page.hero.contact_form.fields.total_sqm')}
                helperText={getErrorMessage('sqmValue')}
                error={!!getErrorMessage('sqmValue')}
                name="landlord_contact[total_sqm]"
                onChange={handleChange('sqmValue')}
                id="total-sqm"
                type="number"
                required
                sx={{ mb: 0.5, width: 1, my: 0.5, ml: [0, 0, 0.25] }}
              />
            </InputWrapper>
            <InputWrapper>
              <LandlordTextField
                className={`owners-${clickSource}modal-bedrooms`}
                placeholder={t('landlords_page.hero.contact_form.fields.bedrooms')}
                label={t('landlords_page.hero.contact_form.fields.bedrooms')}
                sx={{ my: 0.5, mr: [0, 0, 0.25] }}
                name="landlord_contact[bedrooms]"
                id="bedrooms"
              />
              <LandlordTextField
                className={`owners-${clickSource}modal-bathrooms`}
                placeholder={t('landlords_page.hero.contact_form.fields.bathrooms')}
                label={t('landlords_page.hero.contact_form.fields.bathrooms')}
                sx={{ my: 0.5, ml: [0, 0, 0.25] }}
                name="landlord_contact[bathrooms]"
                id="bathrooms"
              />
            </InputWrapper>
            <InputWrapper>
              <LandlordTextField
                className={`owners-${clickSource}modal-parking`}
                placeholder={t('landlords_page.hero.contact_form.fields.parking')}
                label={t('landlords_page.hero.contact_form.fields.parking')}
                sx={{ my: 0.5, mr: [0, 0, 0.25] }}
                name="landlord_contact[parking]"
                id="parking"
              />
              <LandlordTextField
                className={`owners-${clickSource}modal-rental-value`}
                placeholder={t('landlords_page.hero.contact_form.fields.estimated_rental_value')}
                label={t('landlords_page.hero.contact_form.fields.estimated_rental_value')}
                name="landlord_contact[estimated_rental_value]"
                sx={{ my: 0.5, ml: [0, 0, 0.25] }}
                id="estimated-rental-value"
              />
            </InputWrapper>
            <LandlordTextField
              className={`owners-${clickSource}modal-remarks`}
              placeholder={t('landlords_page.hero.contact_form.fields.remarks')}
              label={t('landlords_page.hero.contact_form.fields.remarks')}
              name="landlord_contact[remarks]"
              sx={{ mt: 0.5, mb: 1 }}
              margin="dense"
              id="remarks"
              minRows={4}
              multiline
            />
            <FormControl
              error={!!getErrorMessage('agreement')}
              sx={{ pb: 1 }}
              formHelperText={getErrorMessage('agreement')}
            >
              <FormControlLabel
                className={`owners-${clickSource}modal-check-terms`}
                control={
                  <Checkbox
                    name="agreement"
                    value={formValues.agreement}
                    onChange={handleChange('agreement')}
                  />
                }
                label={(
                  <Typography
                    alignSelf="center"
                    fontSize="0.75rem"
                    color="primary.70"
                  >
                    {t('dictionary.policies_agreement_text')}
                    <Link href={t('privacy_policy_path')} target="_blank" mx={0.25}>
                      {t('dictionary.policies_agreement_link')}
                    </Link>
                  </Typography>
                )}
              />
            </FormControl>
            {!currentMember && <ReCAPTCHA sitekey={recaptchaSiteKey} />}
          </Box>
          <SubmitWrapper
            formHelperText={getErrorMessage('failedSubmission')}
            error={!!getErrorMessage('failedSubmission')}
          >
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => onClose()}
              sx={{ mr: [0, 0, 2] }}
            >
              {t('landlords_page.hero.contact_form.cancel')}
            </Button>
            <Button
              className={`owners-${clickSource}modal-get-in-touch`}
              size="large"
              onClick={handleSubmit}
              disabled={loadingStatus}
              loading={loadingStatus}
            >
              {t('landlords_page.hero.contact_form.submit')}
            </Button>
          </SubmitWrapper>
        </>
      ) : (
        <ConfirmationWrapper className={`owners-${clickSource}modal-tym`}>
          <SvgIcon name="success_large" size={100} className={`owners-${clickSource}modal-tym-img`} />
          <Typography variant="h5" sx={{ m: 2 }} textAlign="center">
            {t('landlords_page.hero.contact_form.success_message')}
          </Typography>
          <Button
            href="/"
            variant="contained"
            size="large"
            sx={{ mt: 1 }}
          >
            {t('landlords_page.hero.contact_form.back_button')}
          </Button>
        </ConfirmationWrapper>
      )}
    </ThemeProvider>
  );
};

export default LandlordContactForm;
